/* background: var(--main-bg); */
body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  min-height: 100vh;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

#root {
  width: 100%;
  height: 100%;
}

:root {
  --font-mono: "Roboto Mono", Menlo, Monaco, Courier New, monospace;
}

.link {
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
  color: #008fd8;
  text-decoration: none;
}
